video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}
