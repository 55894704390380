.wpm {
  animation: wpm 1s infinite alternate;
  -webkit-animation: wpm 1s infinite alternate;
}

@keyframes wpm {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.6;
  }
}
