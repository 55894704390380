.light .xAxis > * {
  stroke: #64748b !important;
}
/* .light .xAxis > * > * > * {
  stroke: #64748b !important;
} */

.light tspan {
  fill: #64748b;
  font-weight: 200 !important;
}
.dark tspan {
  fill: #c4c4c4;
  font-weight: 200 !important;
}

.light .yAxis > * {
  stroke: #64748b !important;
}
/* .light .yAxis > * > * > * {
  stroke: #64748b !important;
} */
.dark .xAxis > * {
  stroke: rgba(216, 213, 213, 0.747) !important;
}
/* .dark .xAxis > * > * > * {
  stroke: rgba(197, 197, 197, 0.649) !important;
} */

.dark .yAxis > * {
  stroke: rgba(197, 197, 197, 0.627) !important;
}
/* .dark .yAxis > * > * > * {
  stroke: rgba(197, 197, 197, 0.716) !important;
} */

.recharts-tooltip-wrapper {
  border: none !important;
  outline: none !important;
}
