@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@200;400&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "DM Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

::-webkit-scrollbar {
  width: 0;
}

.dark {
  color: rgb(197, 197, 197);
}
