.cursor-line::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 30px;
  left: 0.5px;
  top: 0px;
  animation: cursor-line 1s infinite;
  -webkit-animation: cursor-line 1s 0.5s infinite;
}

.light .cursor-line::before {
  background: #424242;
}
.cursor-line::before {
  background: #424242;
}

.dark .cursor-line::before {
  background: #a3a2a2;
}

@keyframes cursor-line {
  0% {
    opacity: 1;
  }
  49% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.written {
  color: #bcbcbc;
}
.waiting {
  color: unset;
}
.wrong {
  color: #ff0000a8;
}

.dark .written {
  color: #406790;
}
.dark .waiting {
  color: unset;
}
.dark .wrong {
  color: #b94f4f;
}

.light .written {
  color: #bcbcbc;
}
.light .waiting {
  color: unset;
}
.light .wrong {
  color: #ff0000a8;
}

.dark .writtenbg-space {
  background-color: transparent;
}
.dark .waitingbg-space {
  background-color: transparent;
}
.dark .wrongbg-space {
  background-color: #ff000033;
}

.light .writtenbg-space {
  background-color: transparent;
}
.light .waitingbg-space {
  background-color: transparent;
}
.light .wrongbg-space {
  background-color: #ff010152;
}
